@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.quicksand-fonts {
    font-family: "Quicksand", sans-serif !important;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 600;
}

.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
    letter-spacing: 0.1rem;
}

.poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
    letter-spacing: 0.2rem;
}

.poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
    letter-spacing: 0.2rem;
}

.poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
    letter-spacing: 0.2rem;
}

.poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0.2rem;
}

.poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
    letter-spacing: 0.2rem;
}

.poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.1rem;
}

.poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
    letter-spacing: 0.2rem;
}

.poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0.2rem;
}

.poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
    letter-spacing: 0.2rem;
}

.poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0.2rem;
}

.poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
    letter-spacing: 0.2rem;
}

.poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.2rem;
}

.poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
    letter-spacing: 0.2rem;
}

.poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
    letter-spacing: 0.2rem;
}

.poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
    letter-spacing: 0.2rem;
}

.poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
    letter-spacing: 0.2rem;
}

.poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
    letter-spacing: 0.2rem;
}

.d-block {
    display: block !important;
}
.quicksand-fonts {
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.gilroy-font {
    font-family: "gilroy-bold", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.poppins-font {
    font-family: "Poppins", sans-serif !important;
    font-optical-sizing: auto;
    font-style: normal;
}

.d-none {
    display: none !important;
}
.d-flex {
    display: flex !important;
}
.flex-column {
    flex-direction: column !important;
}
.flex-wrap {
    flex-wrap: wrap !important;
}
.align-items-center {
    align-items: center !important;
}
.align-items-start {
    align-items: start !important;
}
.align-items-end {
    align-items: end !important;
}
.justify-content-center {
    justify-content: center !important;
}
.justify-content-start {
    justify-content: flex-start !important;
}
.justify-content-end {
    justify-content: flex-end !important;
}
.justify-content-between {
    justify-content: space-between !important;
}
.justify-content-around {
    justify-content: space-around !important;
}
.justify-content-evenly {
    justify-content: space-evenly !important;
}

/* Positions */
.p-relative {
    position: relative;
}
.p-absolute {
    position: absolute;
}
.p-fixed {
    position: fixed;
}
.p-sticky {
    position: sticky;
}
.p-static {
    position: static;
}

/* Spacing */
.w-100 {
    width: 100% !important;
}
.w-75 {
    width: 75% !important;
}
.w-50 {
    width: 50% !important;
}
.w-25 {
    width: 25% !important;
}

.m1 {
    margin: var(--app-base-spacing-1) !important;
}
.m2 {
    margin: var(--app-base-spacing-2) !important;
}
.m3 {
    margin: var(--app-base-spacing-3) !important;
}
.m4 {
    margin: var(--app-base-spacing-4) !important;
}
.m5 {
    margin: var(--app-base-spacing-5) !important;
}

.ml1 {
    margin-left: var(--app-base-spacing-1) !important;
}
.ml2 {
    margin-left: var(--app-base-spacing-2) !important;
}
.ml3 {
    margin-left: var(--app-base-spacing-3) !important;
}
.ml4 {
    margin-left: var(--app-base-spacing-4) !important;
}
.ml5 {
    margin-left: var(--app-base-spacing-5) !important;
}

.mr1 {
    margin-right: var(--app-base-spacing-1) !important;
}
.mr2 {
    margin-right: var(--app-base-spacing-2) !important;
}
.mr3 {
    margin-right: var(--app-base-spacing-3) !important;
}
.mr4 {
    margin-right: var(--app-base-spacing-4) !important;
}
.mr5 {
    margin-right: var(--app-base-spacing-5) !important;
}

.mt1 {
    margin-top: var(--app-base-spacing-1) !important;
}
.mt2 {
    margin-top: var(--app-base-spacing-2) !important;
}
.mt3 {
    margin-top: var(--app-base-spacing-3) !important;
}
.mt4 {
    margin-top: var(--app-base-spacing-4) !important;
}
.mt5 {
    margin-top: var(--app-base-spacing-5) !important;
}

.mb1 {
    margin-bottom: var(--app-base-spacing-1) !important;
}
.mb2 {
    margin-bottom: var(--app-base-spacing-2) !important;
}
.mb3 {
    margin-bottom: var(--app-base-spacing-3) !important;
}
.mb4 {
    margin-bottom: var(--app-base-spacing-4) !important;
}
.mb5 {
    margin-bottom: var(--app-base-spacing-5) !important;
}

.p1 {
    padding: var(--app-base-spacing-1) !important;
}
.p2 {
    padding: var(--app-base-spacing-2) !important;
}
.p3 {
    padding: var(--app-base-spacing-3) !important;
}
.p4 {
    padding: var(--app-base-spacing-4) !important;
}
.p5 {
    padding: var(--app-base-spacing-5) !important;
}

.pl1 {
    padding-left: var(--app-base-spacing-1) !important;
}
.pl2 {
    padding-left: var(--app-base-spacing-2) !important;
}
.pl3 {
    padding-left: var(--app-base-spacing-3) !important;
}
.pl4 {
    padding-left: var(--app-base-spacing-4) !important;
}
.pl5 {
    padding-left: var(--app-base-spacing-5) !important;
}

.pr1 {
    padding-right: var(--app-base-spacing-1) !important;
}
.pr2 {
    padding-right: var(--app-base-spacing-2) !important;
}
.pr3 {
    padding-right: var(--app-base-spacing-3) !important;
}
.pr4 {
    padding-right: var(--app-base-spacing-4) !important;
}
.pr5 {
    padding-right: var(--app-base-spacing-5) !important;
}

.pt0 {
    padding-top: 0 !important;
}
.pt1 {
    padding-top: var(--app-base-spacing-1) !important;
}
.pt2 {
    padding-top: var(--app-base-spacing-2) !important;
}
.pt3 {
    padding-top: var(--app-base-spacing-3) !important;
}
.pt4 {
    padding-top: var(--app-base-spacing-4) !important;
}
.pt5 {
    padding-top: var(--app-base-spacing-5) !important;
}

.pb1 {
    padding-bottom: var(--app-base-spacing-1) !important;
}
.pb2 {
    padding-bottom: var(--app-base-spacing-2) !important;
}
.pb3 {
    padding-bottom: var(--app-base-spacing-3) !important;
}
.pb4 {
    padding-bottom: var(--app-base-spacing-4) !important;
}
.pb5 {
    padding-bottom: var(--app-base-spacing-5) !important;
}

.gap-0 {
    gap: 0px !important;
}
.gap-1 {
    gap: var(--app-base-spacing-1) !important;
}
.gap-2 {
    gap: var(--app-base-spacing-2) !important;
}
.gap-3 {
    gap: var(--app-base-spacing-3) !important;
}
.gap-4 {
    gap: var(--app-base-spacing-4) !important;
}
.gap-5 {
    gap: var(--app-base-spacing-5) !important;
}

/* Colors */
.clr-tm {
    color: var(--app-text-main) !important;
}
.clr-tl {
    color: var(--app-text-light) !important;
}
.clr-td {
    color: var(--app-text-dark) !important;
}

.clr-pm {
    color: var(--app-primary-main) !important;
}
.clr-pl {
    color: var(--app-primary-light) !important;
}
.clr-pd {
    color: var(--app-primary-dark) !important;
}
.clr-w {
    color: var(--app-color-white) !important;
}
.clr-b {
    color: var(--app-color-black) !important;
}
.clr-e {
    color: var(--app-color-error) !important;
}

.bg-m {
    background-color: var(--app-primary-main) !important;
}
.bg-l {
    background-color: var(--app-primary-light) !important;
}
.bg-el {
    background-color: var(--app-primary-exlight) !important;
}
.bg-d {
    background-color: var(--app-primary-dark) !important;
}
.bg-w {
    background-color: var(--app-color-white) !important;
}
.bg-b {
    background-color: var(--app-color-black) !important;
}

/* Text Utility */
.font-bold {
    font-weight: 600 !important;
}
.t-center {
    text-align: center !important;
}
.line-h-0 {
    line-height: 0;
}
.line-h-1 {
    line-height: 1;
}

.uppercase {
    text-transform: uppercase;
}
.capitalize {
    text-transform: capitalize;
}
.lowercase {
    text-transform: lowercase;
}

/* Border Utility */
.b-radius-1 {
    border-radius: var(--app-base-spacing-1);
}
.b-radius-2 {
    border-radius: var(--app-base-spacing-2);
}
.b-radius-3 {
    border-radius: var(--app-base-spacing-3);
}
.b-radius-4 {
    border-radius: var(--app-base-spacing-4);
}
.b-radius-5 {
    border-radius: var(--app-base-spacing-5);
}

/* Center Chilren */
.f-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.g-center {
    display: grid !important;
    place-items: center !important;
}

/* Custom Containers */
.page {
    min-height: 100vh !important;
}

.page-section {
    max-width: 1440px;
    margin: auto;
    padding: var(--app-base-spacing-5);
}

.section {
    /* max-width: 1240px !important; */
    max-width: 1600px;
    margin: auto;
    padding: var(--app-base-spacing-5);
}

.section-1240 {
    max-width: 1240px;
    margin: auto;
    padding: var(--app-base-spacing-5);
}

.c-pointer {
    cursor: pointer;
}

.shadow {
    --tw-shadow: 0 10px 15px 4px rgb(0 0 0 / 0.1), 0 4px 6px 2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ellipsis-2line {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.ellipsis-3line {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.ellipsis-6line {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.ellipsis-3line + button {
    display: inline-block;
    vertical-align: top;
    margin-top: -1px;
}
.main-section {
    position: relative;
}

.main-section .contact-buttons {
    position: fixed;
    right: 0;
    top: 8rem;
    z-index: 99;
}

.main-section .contact-buttons .btn {
    padding: var(--app-base-spacing-1) var(--app-base-spacing-2);
    display: flex;
    align-items: center;
    width: 100%;
    /* overflow: hidden; */
    border-radius: 12px 0px 0px 12px;
    position: absolute;
    right: 0;
    /* transition: width 0.5s; */
}

.main-section .contact-buttons .btn.btn-blue {
    translate: 185px;
    transition: translate 0.5s;
}
.main-section .contact-buttons .btn.btn-yellow {
    translate: 135px;
    transition: translate 0.5s;
}
.main-section .contact-buttons .btn.btn-blue:hover {
    translate: 0;
    transition: translate 0.5s;
}
.main-section .contact-buttons .btn.btn-yellow:hover {
    translate: 0;
    transition: translate 0.5s;
}

.main-section .contact-buttons .btn:hover {
    width: 100%;
}

.main-section .contact-buttons .btn span {
    color: var(--app-color-white);
    font-weight: 600;
    font-size: 16px;
    transition: display 1s;
    text-wrap: nowrap;
    margin-left: var(--app-base-spacing-1);
}

.main-section .contact-buttons .btn:hover span {
    display: block;
}

.main-section .contact-buttons .btn.btn-blue {
    width: auto;
    background-color: var(--app-primary-main);
    top: 0px;
}

.main-section .contact-buttons .btn.btn-yellow {
    width: auto;
    background-color: var(--app-color-warning);
    top: 50px;
}

@media screen and (max-width: 768px) {
    .page-section {
        padding: var(--app-base-spacing-3) !important;
    }
    .section {
        padding: 20px !important;
    }
    .flex-wrap-m {
        flex-wrap: wrap !important;
    }
    .m0-m {
        margin: 0 !important;
    }
    .p0-m {
        padding: 0 !important;
    }
    .mt1-m {
        margin-top: var(--app-base-spacing-1) !important;
    }
    .mt2-m {
        margin-top: var(--app-base-spacing-2) !important;
    }
    .justify-content-center-m {
        justify-content: center !important;
    }
}

@media screen and (max-width: 480px) {
    .page {
        margin-top: 90px;
    }
    .page-section {
        padding: var(--app-base-spacing-2) !important;
    }
    .gap-s-0 {
        gap: 0px !important;
    }
    .ws-100 {
        width: 100% !important;
    }
    .m0-s {
        margin: 0 !important;
    }
    .p0-s {
        padding: 0 !important;
    }
    .pt0-s {
        padding-top: 0 !important;
    }
    .pb0-s {
        padding-bottom: 0 !important;
    }
    .justify-content-center-s {
        justify-content: center !important;
    }
}

@media screen and (max-width: 360px) {
    .page-section {
        padding: var(--app-base-spacing-2) !important;
    }
    .section {
        padding: var(--app-base-spacing-2) !important;
    }
    .section-1240 {
        padding: var(--app-base-spacing-2) !important;
    }
    .flex-wrap-s {
        flex-wrap: wrap !important;
    }
}
