.home-page {
    --bg-card-hover: black;
}

.portfolio-page .products-section .product-categories-container {
    display: flex;
    align-items: stretch;
    margin-bottom: var(--app-base-spacing-5);
}

.portfolio-page .products-section .product-categories-container .categories {
    display: flex;
    align-items: center;
    overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.portfolio-page .products-section .product-categories-container .categories::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.portfolio-page .products-section .product-categories-container .categories {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.portfolio-page .products-section .product-categories-container .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.portfolio-page .products-section .product-categories-container .right-arrow {
    margin-left: var(--app-base-spacing-2);
    margin-right: 0 !important;
}

.portfolio-page .products-section .product-categories-container .left-arrow,
.portfolio-page .products-section .product-categories-container .right-arrow,
.portfolio-page .products-section .product-categories-container .categories .category {
    margin-right: var(--app-base-spacing-2);
    padding: var(--app-base-spacing-1) var(--app-base-spacing-2);
    background-color: var(--app-primary-light);
    font-weight: 500;
    font-size: 18px;
    white-space: nowrap;
    border-radius: var(--app-base-spacing-1);
    cursor: pointer;
}

.portfolio-page .contact-section .border {
    border: 1px solid gray;
    border-radius: 3px;
    padding: 10px;
}

.portfolio-page .products-section .product-categories-container .categories .category.active {
    background-color: var(--app-primary-main);
}

.portfolio-page .products-section .product-categories-container .categories .category.active p {
    color: var(--app-color-white);
}

.portfolio-page .products-section .card-container .card {
    display: flex;
    width: 100%;
    height: 300px;
    overflow: hidden;
    padding: var(--app-base-spacing-3) var(--app-base-spacing-3);
    margin-bottom: var(--app-base-spacing-3);
    background-color: var(--app-primary-exlight);
    /* box-shadow: 0px 6px 18px rgba(16, 24, 40, 0.08); */
    border-radius: 0.75rem;
}

.portfolio-page .products-section .card-container .card .card-header img {
    transition: all 0.3s;
}

.portfolio-page .products-section .card-container .card:hover .card-header img {
    transform: scale(1.2);
}

.portfolio-page .products-section .card-container .card .card-header {
    width: 45%;
    max-height: calc(370px - var(--app-base-spacing-2));
    margin-right: var(--app-base-spacing-3);
}

.portfolio-page .products-section .card-container .card .card-header .card-image {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.portfolio-page .products-section .card-container .card .card-header .card-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.portfolio-page .products-section .card-container .card .card-body {
    width: 55%;
}

.portfolio-page .products-section .card-container .card .card-body .card-heading {
    margin-bottom: var(--app-base-spacing-1);
    font-weight: 600;
    line-height: 43px;
}

.portfolio-page .products-section .card-container .card .card-body .card-description {
    margin-bottom: var(--app-base-spacing-3);
    color: var(--app-text-light) !important;
    font-weight: 500;
    line-height: 26px;
}
.portfolio-page .products-section .card-container .card .card-body .card-description p {
    color: var(--app-text-light) !important;
}
.portfolio-page .products-section .card-container .card .card-body .card-description span {
    color: var(--app-text-light) !important;
}

.portfolio-page .products-section .card-container .card .card-body .card-gallery {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: var(--app-base-spacing-2);
}

.portfolio-page .products-section .card-container .card .card-body .card-gallery .tech-img {
    margin-right: var(--app-base-spacing-2);
}

.portfolio-page .products-section .card-container .card .card-body .card-gallery .tech-img img {
    height: 1.5rem;
    width: 1.5rem;
    object-fit: contain;
}

.portfolio-page .contact-section {
    padding: var(--app-base-spacing-3);
    box-shadow: 0px 6px 18px rgba(16, 24, 40, 0.08);
    border-radius: 12px;
}

.portfolio-page .contact-section form > * {
    margin-bottom: var(--app-base-spacing-1);
}

.project-page .hero-section .featured-image img {
    width: 35rem;
}
.project-page .solution-statement .image img {
    width: 32rem;
}

.project-page .problem-statement .image img {
    width: 32rem;
}
.project-page .expertise-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.project-page .expertise-section::after {
    content: "";
    height: 358px;
    width: 560px;
    background-image: url(../img/bg-3.png);
    background-repeat: no-repeat;
    background-position: right;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}
.project-page .problem-statement .content {
    display: flex;
    align-items: flex-end;
    gap: 70px !important;
    justify-content: space-evenly;
}
.project-page .problem-statement .details ul li {
    display: flex;
    align-items: center;
    margin-block: 10px;
}
.project-page .problem-statement .details ul li::before {
    content: "•"; /* Insert content that looks like bullets */
    padding-right: 8px;
    font-size: 40px;
    color: var(--app-primary-main); /* Or a color you prefer */
}
.project-page .solution-statement .content {
    display: flex;
    align-items: center;
    gap: 70px !important;
    justify-content: space-evenly;
}
.project-page .solution-statement .details ul li {
    display: flex;
    align-items: center;
    margin-block: 10px;
}
.project-page .solution-statement .details ul li::before {
    content: "•"; /* Insert content that looks like bullets */
    padding-right: 8px;
    font-size: 40px;
    color: var(--app-primary-main); /* Or a color you prefer */
}

.project-page .expertise-section::before {
    content: "";
    height: 358px;
    width: 560px;
    background-image: url(../img/bg-4.png);
    background-repeat: no-repeat;
    background-position: initial;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}
.project-page .expertise-section .card-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    gap: var(--app-base-spacing-3);
}
.project-page .expertise-section .card-container .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* width: 145px; */
}
.project-page .expertise-section .card-container .card .card-header {
    margin-bottom: var(--app-base-spacing-2);
}
.project-page .expertise-section .card-container .card .card-header .card-image {
    width: 100px;
    margin: 0 auto;
    border-radius: 100px;
}
.project-page .expertise-section .card-container .card .card-header .card-image img {
    width: 80%;
}

.project-page .expertise-section .card-container .card .card-body .card-heading,
.project-page .expertise-section .card-container .card .card-body .card-description {
    color: var(--app-color-white);
    margin-bottom: var(--app-base-spacing-1);
    text-align: center;
}
.project-page .expertise-section .card-container .card .card-body .card-description {
    margin-bottom: 0;
}

.project-page .process-section .heading-highlighted::before {
    background-color: white !important;
}
.project-page .process-section .heading-highlighted::after {
    background-color: white !important;
}
.project-page .process-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.project-page .process-section::after {
    content: "";
    height: 358px;
    width: 560px;
    background-image: url(../img/bg-3.png);
    background-repeat: no-repeat;
    background-position: right;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}
.project-page .process-section::before {
    content: "";
    height: 358px;
    width: 560px;
    background-image: url(../img/bg-4.png);
    background-repeat: no-repeat;
    background-position: initial;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.project-page .proccess-card {
    position: relative;
    min-height: 14rem;
    height: auto;
}
.project-page .proccess-card .card-container {
    position: absolute;
    top: -5rem;
    z-index: 10;
    flex-wrap: nowrap;
}
.project-page .proccess-card .card-container .card:nth-of-type(1) {
    --bg-card-hover: var(--app-primary-main);
}
.project-page .proccess-card .card-container .card:nth-of-type(2) {
    --bg-card-hover: #f8369a;
}
.project-page .proccess-card .card-container .card:nth-of-type(3) {
    --bg-card-hover: #68e310;
}
.project-page .proccess-card .card-container .card {
    position: relative;
    max-width: 330px;
    width: 100%;
    padding: var(--app-base-spacing-2) var(--app-base-spacing-2);
    margin-left: var(--app-base-spacing-3);
    margin-right: var(--app-base-spacing-3);
    margin-top: var(--app-base-spacing-4);
    border-radius: 6px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    z-index: 0;
    height: 15rem;
}
/* .project-page .proccess-card .card-container .card::after {
    content: attr(data-index);
    position: absolute;
    top: 10px;
    right: 15px;
    color: var(--app-primary-exlight);
    font-size: 100px;
    font-weight: 700;
    line-height: 1;
    z-index: 0;
} */

.project-page .proccess-card .card-container .card:hover:before {
    content: "";
    position: absolute;
    /* background-color: #dfe8fb; */
    z-index: -1;
    top: 9px;
    left: 9px;
    height: 45px;
    width: 45px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.5s ease-out;
}

.project-page .proccess-card .card-container .card:hover {
    border-bottom: 6px solid var(--bg-card-hover);
}

.project-page .card-header .image {
    transition: all 0.3s;
    width: 100%;
    padding: 40px;
}

.project-page .card-header img:hover {
    transform: scale(1.2);
}

.project-page .proccess-card .card-container .card .card-heading {
    font-size: 26px;
    font-weight: 600;
}
.project-page .proccess-card .card-container .card:hover .card-heading {
    color: var(--bg-card-hover);
}

.project-page .content .project-descreption p {
    color: #7b7b7b !important;
}

.project-page .content .project-descreption p span {
    color: #7b7b7b !important;
}

.project-page .content .project-task p {
    color: #7b7b7b !important;
}

@media screen and (max-width: 920px) {
    .portfolio-page .products-section .card-container .card {
        flex-wrap: wrap;
        height: auto;
    }

    .portfolio-page .products-section .card-container .card .card-header {
        width: 100%;
    }

    .portfolio-page .products-section .card-container .card .card-body {
        width: 100%;
    }
    .portfolio-page .contact-section {
        padding: var(--app-base-spacing-2);
    }

    .project-page .proccess-card {
        min-height: 26rem;
    }
    .project-page .proccess-card .card-container {
        flex-wrap: wrap;
    }
    .project-page .hero-section .featured-image img {
        width: 100%;
    }
    .project-page .hero-section .content {
        flex-wrap: wrap-reverse;
    }

    .project-page .solution-statement .content {
        flex-wrap: wrap-reverse;
    }
    .project-page .solution-statement .details {
        width: 100% !important;
    }
    .project-page .solution-statement .image {
        width: 100% !important;
    }

    .project-page .solution-statement .image img {
        width: 100% !important;
    }

    .project-page .problem-statement .content {
        flex-wrap: wrap;
    }
    .project-page .problem-statement .image {
        width: 100% !important;
    }
    .project-page .problem-statement .details {
        width: 100% !important;
    }
    .project-page .problem-statement .image img {
        width: 100% !important;
    }
}

@media screen and (max-width: 756px) {
    .project-page .proccess-card {
        min-height: 45rem;
    }
}
@media screen and (max-width: 480px) {
    .portfolio-page .contact-section {
        padding: var(--app-base-spacing-1);
    }
}
