.blog-page .top-section .category-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: var(--app-base-spacing-3);
}
.blog-page .top-section .category-container .category {
    background-color: var(--app-primary-light);
    padding: var(--app-base-spacing-1) var(--app-base-spacing-2);
    border-radius: var(--app-base-spacing-1);
    cursor: pointer;
    transition: all 0.2s;
}
.blog-page .top-section .category-container .category.active {
    background-color: var(--app-primary-main);
}
.blog-page .top-section .category-container .category.active p {
    color: var(--app-color-white);
    font-weight: 600;
}

.blog-page .blog-section .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: var(--app-base-spacing-3);
}
.blog-page .blog-section .card-container .card {
    padding: var(--app-base-spacing-2);
    max-width: 440px;
    width: 32%;
    height: auto;
    box-shadow: 0px 6px 18px rgba(16, 24, 40, 0.1);
    border-radius: 12px;
}
.blog-page .blog-section .card-container .card .card-head .card-image img {
    max-width: 574px;
    width: 100%;
    border-radius: var(--app-base-spacing-2);
    border: 1px solid rgb(234, 233, 233);
    overflow: hidden;
}
.blog-page .blog-section .card-container .card .card-body h3 {
    margin-top: var(--app-base-spacing-1);
    margin-bottom: var(--app-base-spacing-1);
}

.blog-page .blog-section .card-container .card .card-footer {
    display: flex;
    align-items: center;

    gap: var(--app-base-spacing-5);
    margin-top: var(--app-base-spacing-2);
}
.blog-page .blog-section .card-container .card .card-footer svg {
    fill: var(--app-primary-main);
}

.blog-view-page .contant .table ul {
    list-style: none;
}
.blog-view-page .contant .navigate-before::before {
    content: "";
    display: block;
    position: relative;
    width: 0;
    height: 5.2em;
    margin-top: -5.2em;
    pointer-events: none;
}
.blog-view-page .contant .table ul li::before {
    content: "•"; /* Insert content that looks like bullets */
    padding-right: 8px;
    font-size: 40px;
    color: var(--app-primary-main); /* Or a color you prefer */
}
.blog-view-page .contant .table ul li {
    display: flex;
    align-items: flex-end;
    gap: 5px !important;
    align-items: center;
}

@media screen and (max-width: 940px) {
    .blog-page .blog-section .card-container {
        justify-content: center;
    }
    .blog-page .blog-section .card-container .card {
        width: 100%;
    }
}
@media screen and (max-width: 768px) {
    .blog-page .blog-section .card-container {
        justify-content: center;
    }
    .blog-page .blog-section .card-container .card {
        width: 100%;
        flex-wrap: wrap;
    }

    .blog-view-page .contant > div {
        flex-wrap: wrap;
    }
    .blog-view-page .contant .table {
        min-width: 300px !important;
    }
}
