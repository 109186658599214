@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Patua+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");
@import url("https://fonts.cdnfonts.com/css/poppins");
@font-face {
    font-family: Gilroy-Bold;
    font-style: normal;
    font-weight: 200;
    letter-spacing: 2px;
    src: url(./assets/fonts/Gilroy-Bold.ttf) format("truetype");
}

:root {
    /* Colors */
    --app-primary-dark: #0034de;
    --app-primary-main: #3776ff;
    --app-primary-light: #d6e3ff;
    --app-primary-exlight: #f6f9ff;

    --app-text-dark: #000000;
    --app-text-main: #171c26;
    --app-text-light: #596274;

    --app-color-white: #ffffff;
    --app-color-black: #000000;
    --app-color-error: #ff0000;
    --app-color-warning: #ffa121;
    --app-color-body: #f9f9ff;

    /* Spacing */
    --app-base-spacing-1: 8px;
    --app-base-spacing-2: 16px;
    --app-base-spacing-3: 24px;
    --app-base-spacing-4: 32px;
    --app-base-spacing-5: 40px;

    /* SwiperJs Theme */
    --swiper-theme-color: var(--app-primary-main) !important;
}

/* ::-webkit-scrollbar {
    display: none;
} */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: var(--app-text-main);
    scroll-behavior: smooth;
}

body {
    font-family: "Outfit", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: var(--app-color-body); */
}

code {
    font-family: "source-code-pro", "Menlo", "Monaco", "Consolas", "Courier New", monospace;
}

a {
    text-decoration: none;
    color: inherit;
}

.page {
    margin-top: 130px;
}
