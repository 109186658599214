.rating-slider {
    position: relative;
    height: 100%;
    max-width: 500px;
    width: 100%;
    overflow: hidden;
}
.rating-slider .card-container .card::after {
    content: url(../img/up.png);
    position: absolute;
    left: 30px;
    top: -30px;
    z-index: 9;
}
.rating-slider .card-container .card::before {
    content: url(../img/down.png);
    position: absolute;
    right: 30px;
    bottom: -30px;
    z-index: 9;
}
.rating-slider .swiper {
    overflow: visible;
    margin-top: 40px;
    margin-bottom: 10px;
}
.rating-slider .card-container {
    position: relative;
}
.rating-slider .card-container .card {
    position: relative;
    max-width: 803px;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background-color: var(--app-primary-exlight);
}

.rating-slider .rating-slider-pagination > span {
    margin-left: 5px;
}
/* .rating-slider .card-container .card::after {
    content: url(../img/left-colon.png);
    position: absolute;
    right: 30px;
    bottom: -30px;
    z-index: 1;
}

.rating-slider .card-container .card::before {
    content: url(../img/right-colon.png);
    position: absolute;
    left: 30px;
    top: -30px;
} */
.rating-slider .card-container .card .card-image-container {
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rating-slider .card-container .card .card-image-container .card-image {
    width: 140px;
    height: 140px;
    margin-top: calc(var(--app-base-spacing-5) * -1);
    border-radius: 200px;
    /* background: var(--app-primary-main); */
}
.rating-slider .card-container .card .card-content {
    padding: 15px;
    /* max-width: 90%; */
    width: 96%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.rating-slider .card-container .card .card-content .review-text {
    margin-bottom: var(--app-base-spacing-1);
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: var(--app-text-light);
    text-align: center;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
}
.rating-slider .card-container .card .card-content .reviewer-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 35px;
    color: var(--app-primary-main);
}
.rating-slider .card-container .card .card-content .placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: var(--app-text-light);
}

.rating-slider-cards .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}
.rating-slider-cards .card-container {
    max-width: 340px;
    width: 100%;
    padding: var(--app-base-spacing-4) var(--app-base-spacing-2) var(--app-base-spacing-2);
    background-color: var(--app-primary-exlight);
    border-radius: var(--app-base-spacing-4);
    display: flex;
    align-items: center;
    justify-content: center;
}
.rating-slider-cards .card-container .card {
    position: relative;
}
.rating-slider-cards .card-container .card::before {
    content: url(../img/up.png);
    position: absolute;
    left: 0;
    top: -10px;
    z-index: 9;
}
.rating-slider-cards .card-container .card::after {
    content: url(../img/down.png);
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 9;
}
.rating-slider-cards .card-container .card {
    width: 314px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.rating-slider-cards .card-container .card .card-image-container {
    height: 150px;
    width: 150px;
    margin: 0 auto;
    border-radius: 100px;
}
.rating-slider-cards .card-container .card .card-image-container img {
    height: 150px;
    width: 150px;
    margin: 0 auto;
    border-radius: 100px;
}
.rating-slider-cards .slider-navigation .swiper-button-disabled {
    pointer-events: none !important;
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.26) !important;
}
.rating-slider-cards .slider-navigation .swiper-button-disabled svg {
    fill: #7f7f7f !important;
}
