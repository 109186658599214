.menu {
    display: block;
}
.menu ul.ul-menu {
    display: flex;
    align-items: center;
    gap: var(--app-base-spacing-1);
}
.menu ul a {
    padding-inline: 8px;
    padding-block: 4px;
    position: relative;
    transition: all 0.5s;
}
.menu ul a.menu-link::before,
.menu ul a.menu-link::after {
    content: "";
    width: 40px;
    height: 2px;
    background-color: var(--app-primary-main);
    opacity: 0;
    transition: all 0.5s;
    border-radius: 100px;
    position: absolute;
    bottom: 0;
    left: 7px;
    right: 0;
}
.menu ul a.menu-link::before {
    width: 10px;
    left: 53px;
}
.menu ul a.menu-link:hover::before,
.menu ul a.menu-link:hover::after {
    opacity: 1;
}
.menu ul a.menu-link.active::before,
.menu ul a.menu-link.active::after {
    opacity: 1;
}
.menu ul li {
    list-style: none;
    font-size: 18px;
    font-weight: 500;
    /* text-wrap: nowrap; */
    /* margin-block: 2px; */
    /* text-transform: uppercase; */
}
.menu ul li.menu-item.ourworking-menu {
    position: relative;
}
.menu ul li.menu-item.has-submenu .submenu-container {
    width: 317px;
    position: absolute;
    left: 0;
    top: 40px;
    filter: drop-shadow(0px 6px 18px rgba(16, 24, 40, 0.08));
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition: all 0.5s;
    background-color: var(--app-color-white);
    border-radius: var(--app-base-spacing-1);
    z-index: 99;
}
.menu ul li.menu-item.ourworking-menu:hover .submenu-container {
    height: auto;
    opacity: 1;
}
.menu ul li.menu-item.ourworking-menu .submenu-container a {
    position: relative;
    font-size: 16px;
    transition: all 0.3s;
    padding: var(--app-base-spacing-1);
}
.menu ul li.menu-item.ourworking-menu .submenu-container a:hover {
    background: linear-gradient(90.01deg, var(--app-primary-light) 3.57%, var(--app-color-white) 96.96%);
}
.menu ul li.menu-item.ourworking-menu .submenu-container a .before-icon {
    display: block;
    margin-right: var(--app-base-spacing-1);
    margin-bottom: -5px;
}
.menu ul li.menu-item.ourworking-menu .submenu-container a .before-icon svg {
    fill: var(--app-primary-main);
}
.menu ul li.menu-item.ourworking-menu .submenu-container a .after-icon {
    display: block;
    margin-left: auto;
    opacity: 0;
    margin-right: -10px;
    transition: all 0.3s;
}
.menu ul li.menu-item.ourworking-menu .submenu-container a:hover .after-icon {
    opacity: 1;
    margin-right: -0;
}
.menu ul li.menu-item.ourworking-menu .submenu-container a .after-icon svg {
    fill: var(--app-primary-main);
}

/* Mega Menu */
.menu .menu-item.service-menu:hover .mega-menu.submenu-container {
    display: block;
    height: auto;
    opacity: 1;
}
.menu .mega-menu {
    position: absolute;
    left: 50%;
    max-width: 1130px;
    width: 100%;
    margin: 0 auto;
    transform: translateX(-50%);
    filter: drop-shadow(0px 6px 18px rgba(16, 24, 40, 0.08));
    border-radius: var(--app-base-spacing-2);
    overflow: auto;
    height: 0;
    opacity: 0;
    transition: all 0.5s;
    background-color: var(--app-color-white);
    z-index: 99;
}
.sticky-header .menu .mega-menu {
    top: 64px;
}
.menu .mega-menu .megamenu-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: 80vh;
    overflow-y: auto;
    padding: var(--app-base-spacing-2);
}
.menu .mega-menu .megamenu-container .submenu {
    max-width: 400px;
    width: 25%;
    min-width: 270px;
    padding-right: var(--app-base-spacing-2);
    padding-bottom: var(--app-base-spacing-2);
}
.menu .mega-menu .megamenu-container .submenu .submenu-headding {
    margin-bottom: 2px;
}
.menu .mega-menu .megamenu-container .submenu .submenu-list a {
    position: relative;
    font-size: 16px;
    transition: all 0.3s;
}
.menu .mega-menu .megamenu-container .submenu .submenu-list a:hover {
    background: linear-gradient(90deg, #f0f5ff 3.57%, #fafbff 96.96%);
}

.menu .mega-menu .megamenu-container .submenu .submenu-list a .before-icon {
    display: block;
    margin-right: var(--app-base-spacing-1);
    margin-bottom: -5px;
}
.menu .mega-menu .megamenu-container .submenu .submenu-list a .before-icon svg {
    fill: var(--app-primary-main);
}
.menu .mega-menu .megamenu-container .submenu .submenu-list a .after-icon {
    display: block;
    margin-left: auto;
    opacity: 0;
    margin-right: -10px;
    transition: all 0.3s;
}
.menu .mega-menu .megamenu-container .submenu .submenu-list a:hover .after-icon {
    opacity: 1;
    margin-right: -0;
}
.menu .mega-menu .megamenu-container .submenu .submenu-list a .after-icon svg {
    fill: var(--app-primary-main);
}

/* Off Screen Menu */
.offscreen-menu {
    position: fixed;
    top: 0;
    left: -400px;
    bottom: 0;
    z-index: 999999;
    transition: all 0.3s;
    scroll-behavior: smooth;
    scrollbar-width: 0;
    display: none;
    width: 100%;
}
.offscreen-menu::after {
    content: "";
    position: fixed;
    top: 0;
    right: -400px;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
}
.offscreen-menu.active {
    display: block;
    left: 0;
}
.offscreen-menu.active::after {
    left: 0;
}
.offscreen-menu .menu {
    padding: var(--app-base-spacing-2);
    /* margin-top: var(--app-base-spacing-2); */
    background-color: var(--app-color-white);
    max-width: 30rem;
    width: 100%;
    height: auto;
    position: relative;
    /* box-shadow: 0px 6px 18px rgba(16, 24, 40, 0.08); */
}
.offscreen-menu .menu ul {
    flex-direction: column;
    align-items: flex-start;
}

@media screen and (max-width: 1200px) {
    .menu ul.ul-menu {
        gap: 0;
    }
}

@media screen and (max-width: 920px) {
    .menu ul li {
        font-size: 18px;
    }

    .menu .mega-menu {
        transform: none;
        left: auto;
        width: 325px;
    }

    .menu ul.ul-menu {
        gap: var(--app-base-spacing-1);
    }
}

@media screen and (max-width: 460px) {
    .menu ul li {
        list-style: none;
        font-size: 20px;
    }

    /* Off Screen Menu */
    .offscreen-menu {
        left: -300px;
    }
    .offscreen-menu::after {
        right: -300px;
    }
}
