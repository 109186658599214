.casestudy-page .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.casestudy-page .card-container .card {
    margin-bottom: var(--app-base-spacing-5);
    width: 100%;
    max-width: 48%;
    box-shadow: 0px 6px 18px rgba(16, 24, 40, 0.15);
    border-radius: 12px;
}
.casestudy-page .card-container .card .card-body {
    padding-inline: 20px;
    width: 100%;
}
.casestudy-page .card-container .card .card-head .card-image,
.casestudy-page .card-container .card .card-head .card-image img {
    max-width: 550px;
    max-height: 400px;
    object-fit: contain;
    padding-block: 10px;
    width: 100%;
    border-radius: var(--app-base-spacing-2);
    overflow: hidden;
    margin: auto;
}
.casestudy-page .card-container .card .card-body h3 {
    margin-top: var(--app-base-spacing-2);
    margin-bottom: var(--app-base-spacing-2);
}
.casestudy-page .card-container .card .card-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-block: var(--app-base-spacing-3);
}

.casestudy-page .card-container .card .card-footer img {
    height: 2.5rem;
    width: 2.5rem;
    object-fit: contain;
}

@media screen and (max-width: 900px) {
    .casestudy-page .card-container .card {
        flex-wrap: wrap;
    }
    .casestudy-page .card-container .card .card-body {
        width: 100%;
    }
    .casestudy-page .card-container .card .card-head .card-image img {
        width: 100%;
    }
}
@media screen and (max-width: 900px) {
    .case-view-page .contant > div {
        flex-wrap: wrap;
    }
    .case-view-page .contant .table {
        min-width: 300px !important;
    }
}
