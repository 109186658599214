/* .home-page .spacer-home-hero {
    height: 80px;
} */
.home-page {
    --shadow: black;
}

.transform-gpu {
    will-change: transform;
}

.home-page .hero-section {
    position: relative;
    max-width: 80vw;
    margin: auto;
}
.home-page .hero-section .hero-left .heading {
    padding-top: 60px;
    line-height: 53px;
    font-size: 40px;
}
.home-page .hero-right .hero-img-box .hero-img {
    min-width: 400px;
    max-width: 600px;
    width: 100%;
    /* margin-left: auto; */
}
.home-page .hero-right .partners .partners-img {
    max-width: 500px;
    width: 100%;
    z-index: 2;
    position: relative;
}
.home-page .hero-right .partners::after {
    content: "";
    position: absolute;
    bottom: var(--app-base-spacing-4);
    right: 0;
    width: 50%;
    height: 100%;
    max-height: 120px;
    background: linear-gradient(
        270.12deg,
        #dce6ff 0.1%,
        rgba(224, 234, 255, 0.421875) 79.32%,
        rgba(238, 243, 254, 0) 106.07%
    );
    z-index: 1;
}

.home-page .info-section .heading {
    font-weight: 600;
    font-size: 34px;
}
.home-page .info-section .description {
    font-weight: 400;
    font-size: 22px;
}

.home-page .achived-section {
    position: relative;
    /* background: var(--app-primary-exlight); */
    /* background-repeat: no-repeat; */
    background-size: contain;
    background-position: center;
}
.home-page .achived-section .card-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    /* row-gap: 30px; */
    min-width: 500px;
    margin-bottom: 24px;
}
.home-page .achived-section .card-container .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-page .achived-section .card-container .card-head {
    /* background-color: var(--app-color-white); */
    /* height: 80px; */
    /* width: 80px; */
    border-radius: 100px;
    /* box-shadow: 0px 4px 25px rgba(55, 118, 255, 0.24); */
    margin-bottom: var(--app-base-spacing-1);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.home-page .achived-section .card-container .card-head .first-500::after {
    font: 800 40px system-ui;
    content: counter(count);
    animation: counter 5s linear alternate;
    counter-reset: count 500;
}

.home-page .service-section .filtering .filter {
    padding: 7px;
    background: var(--app-primary-light);
    border-radius: 30px;
    display: inline-block;
}
.home-page .service-section .filtering .filter .active {
    color: blue;
    font-weight: bold;
}

.home-page .service-section .filtering .slick-prev span {
    position: absolute;
    left: -40px;
}

.home-page .service-section .filtering .slick-arrow span {
    height: 20px !important;
    width: 20px !important;
}

.home-page .service-section .filtering.smplx span,
.home-page .service-section .filtering span {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 20px !important;
    cursor: pointer;
    position: relative;
}

.home-page .service-section .filtering.smplx .dot:after,
.home-page .service-section .filtering .dot:after {
    content: "";
    width: 7px;
    height: 7px;
    background: var(--app-primary-dark);
    border-radius: 50%;
    position: absolute;
    right: -24px;
    top: 35%;
}

/* .home-page .service-section .items span {
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    width: initial;
    height: initial;
    opacity: 1;
    border: 0px;
    margin: 0px;
    padding: 0px;
    position: relative;
    max-width: 100%;
}
.home-page .service-section .items span img {
    position: relative;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    min-width: 100%;
    max-width: 50px;
    min-height: 100%;
    max-height: 100%;
    visibility: visible;
    animation-name: fadeInUp;
} */

.home-page .service-section .items span img {
    -webkit-animation: bounce-in-top 1.1s both;
    animation: bounce-in-top 1.1s both;
}
.home-page .service-section .card-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.home-page .service-section .card-container .card {
    width: 327px;
    padding: var(--app-base-spacing-3) var(--app-base-spacing-2);
    margin-left: var(--app-base-spacing-5);
    margin-bottom: var(--app-base-spacing-5);
    background: var(--app-color-white);
    box-shadow: 0px 6px 18px rgba(16, 24, 40, 0.08);
    border-radius: 12px;
    transition: all 1s;
    overflow: hidden;
}
.home-page .service-section .card-container .card:hover {
    background: url(../img/CardHoverBg.png);
    background-size: cover;
    transform: scale(1.1);
    background-repeat: no-repeat;
}
.home-page .service-section .card-container .card .card-head {
    margin-bottom: var(--app-base-spacing-2);
}
.home-page .service-section .card-container .card .card-head .card-img {
    max-width: 120px;
    width: 100%;
    margin: auto;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.home-page .service-section .card-container .card .card-head .card-img img {
    filter: brightness(10);
    z-index: 1;
    position: absolute;
    left: 20%;
    top: 35%;
}
.home-page .service-section .card-container .card:hover .card-head .card-img img {
    filter: brightness(1);
    position: absolute;
    left: 20%;
    top: 35%;
}
.home-page .service-section .card-container .card .card-head .card-img .card-img-bg {
    z-index: -1;
}

.home-page .service-section .card-container .card .card-head .card-img .card-img-bg svg .logo-bg {
    fill: var(--app-primary-main);
}
.home-page .service-section .card-container .card:hover .card-head .card-img .card-img-bg svg .logo-bg {
    fill: var(--app-color-white);
}
.home-page .service-section .card-container .card .card-body .card-heading {
    margin-bottom: var(--app-base-spacing-1);
}
.home-page .service-section .card-container .card:hover .card-body .card-heading {
    color: var(--app-color-white);
}
.home-page .service-section .card-container .card:hover .card-body .card-description {
    color: var(--app-color-white);
}

.workflow__slider {
    /* width: 100%; */
}
.workflow__slide {
    text-align: center;
    position: relative;
    max-width: 270px;
    min-width: 270px;
    width: 100%;
}

/* @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .workflow__slide {
        padding: 0 10px;
    }
} */
.workflow__slide {
    padding: 0 10px;
}

.workflow__slide:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    left: 0;
    top: 38px;
    background-color: var(--app-color-black);
}

.workflow__slide:after {
    position: absolute;
    content: "";
    width: 18px;
    height: 18px;
    left: calc(50% - 9px);
    top: 30px;
    background-color: var(--app-primary-main);
    border: 3px solid var(--app-color-white);
    border-radius: 100%;
    animation: wcBubble 2s 1s infinite;
    transition: all 0.3s;
}

.workflow__slider .workflow-wrapper {
    display: flex;
    justify-content: center;
}
.workflow__slider .workflow-wrapper .swiper-slide {
    width: 18%;
}
.workflow__slide p {
    max-width: 230px;
    margin: 0 auto;
    color: #555;
}

.workflow__step {
    font-weight: 400;
    font-size: 18px !important;
    line-height: 1.3;
    color: var(--app-color-black);
    text-transform: capitalize !important;
    padding-bottom: 20px;
}

.workflow__number {
    font-size: 85px !important;
    font-weight: 700 !important;
    color: #eeeeee !important;
}

.workflow__title {
    font-weight: 500 !important;
    font-size: 22px !important;
    line-height: 1.2;
    color: var(--app-color-black);
    padding-bottom: 15px !important;
}

.home-page .who-section .content {
    display: flex;
    gap: var(--app-base-spacing-5);
    padding-top: var(--app-base-spacing-3);
}
.home-page .who-section .content .left .image {
    margin: auto;
    width: 65%;
}
.home-page .who-section .content .left,
.home-page .who-section .content .right {
    width: 50%;
    /* padding: var(--app-base-spacing-1); */
}

.home-page .industries-section .card-container .card {
    max-width: 167px;
    width: 100%;
    height: 160px;
    background-color: var(--app-color-white);
    box-shadow: 0px 6px 18px rgba(16, 24, 40, 0.08);
    border-radius: var(--app-base-spacing-1);
    padding: 4px;
}
.home-page .industries-section .card-container .shadow-pop-tr {
    border: 1px rgb(239, 239, 239) solid;
}
.home-page .industries-section .card-container .shadow-pop-tr .card-heading {
    color: var(--shadow);
}

.home-page .industries-section .card-container .shadow-pop-tr:hover {
    animation: shadow-pop-tr 0.6s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}
.home-page .industries-section .card-container .shadow-pop-tr:nth-of-type(1) {
    --shadow: var(--app-primary-main);
}
.home-page .industries-section .card-container .shadow-pop-tr:nth-of-type(2) {
    --shadow: #e837ff;
}
.home-page .industries-section .card-container .shadow-pop-tr:nth-of-type(3) {
    --shadow: #fd8437;
}
.home-page .industries-section .card-container .shadow-pop-tr:nth-of-type(4) {
    --shadow: #fd3762;
}
.home-page .industries-section .card-container .shadow-pop-tr:nth-of-type(5) {
    --shadow: #77bf44;
}
.home-page .industries-section .card-container .shadow-pop-tr:nth-of-type(6) {
    --shadow: #d83527;
}
.home-page .industries-section .card-container .shadow-pop-tr:nth-of-type(7) {
    --shadow: #851bd0;
}
.home-page .industries-section .card-container .shadow-pop-tr:nth-of-type(8) {
    --shadow: #fec546;
}
.home-page .industries-section .card-container .shadow-pop-tr:nth-of-type(9) {
    --shadow: #3fa5e3;
}
.home-page .industries-section .card-container .shadow-pop-tr:nth-of-type(10) {
    --shadow: #373aff;
}
.home-page .industries-section .card-container .shadow-pop-tr:nth-of-type(11) {
    --shadow: #f47fbc;
}
.home-page .industries-section .card-container .shadow-pop-tr:nth-of-type(12) {
    --shadow: #ed7267;
}

.home-page .whychooseus-section .card-container .card {
    max-width: 397px;
    width: 100%;
    height: 94px;
    background-color: var(--app-color-white);
    box-shadow: 0px 6px 18px rgba(16, 24, 40, 0.08);
    border-radius: var(--app-base-spacing-1);
    margin-right: var(--app-base-spacing-3);
    margin-bottom: var(--app-base-spacing-3);
}
.home-page .whychooseus-section .card-container .card .card-head {
    width: 102px;
}

.home-page .whychooseus-section .card-container .card .card-body {
    width: 100%;
    background-image: linear-gradient(to right, var(--shadow), var(--shadow) 50%, var(--shadow, 0.5) 50%);
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    position: relative;
    padding-left: var(--app-base-spacing-3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.5s ease-in-out;
}

.home-page .whychooseus-section .card-container .card:nth-of-type(1) {
    --shadow: var(--app-primary-main);
}
.home-page .whychooseus-section .card-container .card:nth-of-type(2) {
    --shadow: #1aa8f9;
}
.home-page .whychooseus-section .card-container .card:nth-of-type(3) {
    --shadow: #fc5454;
}
.home-page .whychooseus-section .card-container .card:nth-of-type(4) {
    --shadow: #6fda43;
}
.home-page .whychooseus-section .card-container .card:nth-of-type(5) {
    --shadow: #ecd047;
}
.home-page .whychooseus-section .card-container .card:nth-of-type(6) {
    --shadow: #54cafc;
}
.home-page .whychooseus-section .card-container .card:nth-of-type(7) {
    --shadow: #ed6548;
}
.home-page .whychooseus-section .card-container .card:nth-of-type(8) {
    --shadow: #8754ff;
}
.home-page .whychooseus-section .card-container .card:nth-of-type(9) {
    --shadow: #fc54b9;
}

.home-page .whychooseus-section .card-container .card .card-body:before {
    content: "";
    background: var(--shadow);
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    transition: all 0.3s ease-in-out;
}

.home-page .whychooseus-section .card-container .card:hover {
    background-position: 0;
}

.home-page .whychooseus-section .card-container .card:hover .card-body::before {
    width: 100%;
}

.home-page .process-section .card-container .card {
    position: relative;
    max-width: 380px;
    width: 100%;
    padding: var(--app-base-spacing-4) var(--app-base-spacing-4);
    margin-left: var(--app-base-spacing-4);
    margin-top: var(--app-base-spacing-4);
    border-radius: 6px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    z-index: 0;
}
/* .home-page .process-section .card-container .card::after {
    content: attr(data-index);
    position: absolute;
    top: 10px;
    right: 15px;
    color: var(--app-primary-exlight);
    font-size: 100px;
    font-weight: 700;
    line-height: 1;
    z-index: 0;
}
.home-page .process-section .card-container .card:hover::after {
    z-index: 2;
} */
.home-page .process-section .card-container .card:first-child {
    margin-left: 0;
}

.home-page .process-section .card-container .card:nth-of-type(1) {
    --shadow: var(--app-primary-main);
}
.home-page .process-section .card-container .card:nth-of-type(2) {
    --shadow: #00c3ff;
}
.home-page .process-section .card-container .card:nth-of-type(3) {
    --shadow: #be4ef9;
}
.home-page .process-section .card-container .card:nth-of-type(4) {
    --shadow: #62ba38;
}
.home-page .process-section .card-container .card:nth-of-type(5) {
    --shadow: #fa5c3d;
}
.home-page .process-section .card-container .card:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 24px;
    left: 25px;
    background: var(--shadow);
    height: 45px;
    width: 45px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.5s ease-in-out;
}
/* .home-page .process-section .card-container .card:hover::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 24px;
    left: 25px;
    background-color: white;
    height: 45px;
    width: 45px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.5s ease-out;
} */

/* .home-page .process-section .card-container .card:hover .card-icon {
    background-color: #ffffff;
}
.home-page .process-section .card-container .card:hover .card-icon img {
    fill: var(--shadow);
    filter: brightness(1) hue-rotate(180deg);
} */

.home-page .process-section .card-container .card:hover:before {
    transform: scale(25);
    transition: transform 0.7s ease-in-out;
}

.home-page .process-section .card-container .card .card-heading {
    font-size: 26px;
    color: var(--shadow);
    font-weight: 600;
}
.home-page .process-section .card-container .card:hover .card-heading {
    font-size: 26px;
    color: white;
    font-weight: 600;
}

.home-page .process-section .card-container .card .card-descripttion {
    color: var(--shadow) !important;
}

.home-page .process-section .card-container .card:hover .card-descripttion {
    color: white !important;
    position: relative;
    z-index: 10;
}
.home-page .portfolio-section .slider .card {
    display: flex;
    align-items: center;
    padding-inline: var(--app-base-spacing-4);
    border-radius: var(--app-base-spacing-1);
}
.home-page .portfolio-section .slider .card .card-header {
    width: max-content;
    border-radius: var(--app-base-spacing-1);
    position: relative;
}
.home-page .portfolio-section .slider .card .card-header .card-image {
    max-width: 400px;
    height: auto;
    width: 100%;
    object-fit: contain;
    transition: all 0.3s;
    border-radius: 10px;
    overflow: hidden;
    margin-block: 20px;
}
.home-page .portfolio-section .slider .card .card-body {
    width: 100%;
    /* width: max-content; */
    padding-top: var(--app-base-spacing-5);
    margin-top: var(--app-base-spacing-5);
}

.home-page .portfolio-section .slider .card:hover .card-header .card-image {
    transform: scale(1.2);
}

.swiper-wrapper {
    width: 100%;
}

.home-page .portfolio-section .slider .card .card-body .card-heading {
    margin-bottom: var(--app-base-spacing-3);
    /* font-weight: 600; */
    /* font-size: 26px; */
    /* line-height: 33px; */
    opacity: 0.9;
}
.home-page .portfolio-section .slider .card .card-body .card-description {
    margin-bottom: var(--app-base-spacing-2);
    font-weight: 500;
    /* font-size: 16px; */
    /* line-height: 28px; */
}
.home-page .portfolio-section .slider .card .card-body .card-description p {
    -webkit-line-clamp: 4;
    opacity: 0.6;
}
.home-page .portfolio-section .slider .card .card-body .card-btn {
    width: 250px;
    height: auto;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-block: var(--app-base-spacing-3);
}
.home-page .portfolio-section .slider .card .card-body .card-btn .btn-text {
    /* margin-right: var(--app-base-spacing-1); */
    font-weight: 500;
    font-size: 20px;
    opacity: 0.9;
}
.home-page .portfolio-section .slider .cards-pagination > span {
    margin-left: var(--app-base-spacing-1);
}

.home-page .contact-review {
    position: relative;
    background: url("/public/img/image 122.png");
    background-position: center;
    margin-bottom: 150px;
    margin-top: 50px;
    background-size: cover;
    object-fit: cover;
}
.home-page .contact-review::before {
    content: "";
    position: absolute;
    top: 0;

    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
    filter: brightness(40%);
}

/* Adjust brightness of text as needed */
.home-page .contact-review .page-section,
.home-page .contact-review .page-section .review-box {
    filter: brightness(100%);
}

.home-page .contact-review .contact-review-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* height: 55vh; */
}

.home-page .contact-review .review-box {
    max-width: 700px;
    margin-bottom: -180px;
    border-radius: 20px;
    padding: 20px;
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .home-page .industries-section .card-container {
        gap: var(--app-base-spacing-2) !important;
    }
    .home-page .industries-section .card-container .card {
        max-width: 150px;
        height: 140px;
    }
    .home-page .industries-section .card-container .card h6 {
        margin-top: 10px !important;
    }
    .home-page .portfolio-section .slider .card .card-body {
        padding-top: 0;
        margin-top: var(--app-base-spacing-2);
        margin-right: var(--app-base-spacing-5);
        margin-bottom: var(--app-base-spacing-3);
    }
}

@media screen and (max-width: 1000px) {
    .home-page .contact-review {
        margin-bottom: 0px;
    }
    .home-page .contact-review .contact-review-flex {
        display: block;
        height: auto;
    }
    .home-page .contact-review .review-box {
        width: auto;
        margin: auto;
    }
    .home-page .who-section .content {
        flex-wrap: wrap;
    }
    .home-page .who-section .content .left,
    .home-page .who-section .content .right {
        width: 100% !important;
        /* padding: var(--app-base-spacing-1); */
    }
    /* .home-page .achived-section .card-container {
        flex-wrap: wrap;
    } */

    .home-page .hero-right .hero-img-box .hero-img {
        min-width: 300px;
        margin: auto;
    }
}

@media screen and (max-width: 768px) {
    .home-page .hero-section {
        max-width: 100vw !important;
    }
    .home-page .hero-section .hero-left .slide-right {
        background-position: center;
    }
    .home-page .hero-section .hero-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }
    .home-page .hero-section .hero-left .heading {
        padding-top: 30px;
        line-height: 60px;
        text-align: center;
        font-size: 40px;
    }
    .home-page .hero-section .hero-left .description {
        text-align: center;
    }
    .home-page .hero-section .hero-img-box .hero-img {
        width: 100%;
        height: auto;
    }
    .home-page .hero-section .contact-buttons {
        display: none;
    }

    /* .home-page .achived-section .card-container .card {
        width: 25%;
    } */
    .home-page .who-section .content {
        gap: 0;
    }

    .home-page .who-section .content .left,
    .home-page .who-section .content .right {
        width: 100%;
        /* padding: var(--app-base-spacing-1); */
    }

    .home-page .portfolio-section .slider .card {
        flex-wrap: wrap-reverse;
    }
    .home-page .portfolio-section .slider .card .card-body {
        padding-top: 0;
        margin-top: 0;
        margin-right: 0;
    }
    .home-page .achived-section .card-container {
        min-width: auto !important;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 480px) {
    .home-page .hero-section .hero-left .heading {
        padding-top: 10px;
        line-height: 40px;
        text-align: center;
        font-size: 30px;
    }
    .home-page .hero-section .hero-left .description {
        font-size: 16px;
        line-height: 30px;
        margin-top: var(--app-base-spacing-2);
    }
    .home-page .hero-right .partners .partners-img {
        height: auto;
        width: 100%;
    }
    .home-page .hero-right .partners::after {
        max-height: 80px;
        bottom: var(--app-base-spacing-2);
    }
    .home-page .hero-right .hero-img-box .hero-img {
        width: 100%;
        height: auto;
    }

    .home-page .portfolio-section .slider .card .card-body .card-heading {
        margin-top: var(--app-base-spacing-1);
        margin-bottom: var(--app-base-spacing-1);
    }
    /* .home-page .achived-section .card-container .card-head {
        height: 110px;

    } */

    .home-page .process-section .card-container .card {
        margin-left: 0;
    }

    .home-page .work-section .slider .swiper .swiper-wrapper .swiper-slide.swiper-slide-active,
    .home-page .work-section .slider .card.active {
        width: 351px;
    }
    .home-page .work-section .slider .card.active .card-body {
        display: none;
    }

    .home-page .service-section .card-container .card {
        margin-left: 0;
    }
}

@keyframes counter {
    0% {
        counter-increment: count 0;
    }
    10% {
        counter-increment: count 50;
    }
    20% {
        counter-increment: count 100;
    }
    30% {
        counter-increment: count 150;
    }
    40% {
        counter-increment: count 200;
    }
    50% {
        counter-increment: count 250;
    }
    60% {
        counter-increment: count 300;
    }
    70% {
        counter-increment: count 350;
    }
    80% {
        counter-increment: count 400;
    }
    90% {
        counter-increment: count 450;
    }
    100% {
        counter-increment: count 500;
    }
}

@keyframes shadow-pop-tr {
    0% {
        /* box-shadow: 0 0 var(--app-primary-exlight), 0 0 var(--app-primary-exlight), 0 0 var(--app-primary-exlight),
            0 0 var(--app-primary-exlight), 0 0 var(--app-primary-exlight), 0 0 var(--app-primary-exlight),
            0 0 var(--app-primary-exlight), 0 0 var(--app-primary-exlight); */
        transform: translateX(0) translateY(0);
    }
    100% {
        box-shadow: 1px -1px var(--shadow), 2px -2px var(--shadow), 3px -3px var(--shadow), 4px -4px var(--shadow),
            5px -5px var(--shadow), 6px -6px var(--shadow), 7px -7px var(--shadow), 8px -8px var(--shadow);
        transform: translateX(-8px) translateY(8px);
    }
}

@keyframes bounce-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }
    38% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }
    55% {
        -webkit-transform: translateY(-35px);
        transform: translateY(-35px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    72% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    81% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    90% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    95% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes slide-in-blurred-bottom {
    0% {
        -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
        transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        -webkit-transform: translateY(50px);
        -moz-transform: translateY(50px);
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes wcBubble {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        transform: scale(1.2);
    }
    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        transform: scale(1);
    }
}
