button {
    border: 0;
    border-radius: 0;
    border-color: 0;
    padding: 6px 16px;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    font-size: 20px;
}
button:focus,
button:active {
    outline: none;
}

.app-button-container-1 {
    width: fit-content;
    display: inline-block;
    position: relative;
}
.app-button-1 {
    /* padding: 12px 18px 4px; */
    padding: 8px 18px 8px;
    border: 2px solid var(--app-primary-main);
    border-radius: var(--app-base-spacing-1);
    /* border-radius: calc(var(--app-base-spacing-2) * 0.8); */
    position: relative;
    z-index: 1;
    text-align: center !important;
    font-family: "poppins";
}

.app-button-2 {
    /* padding: 12px 18px 4px; */
    padding: 8px 18px 8px;
    border: 2px solid var(--app-primary-main);
    border-radius: var(--app-base-spacing-1);
    /* border-radius: calc(var(--app-base-spacing-2) * 0.8); */
    position: relative;
    z-index: 1;
}

.app-button-container-1 span {
    width: 100%;
    height: 100%;
    background-color: var(--app-primary-light);
    border-radius: var(--app-base-spacing-1);
    /* border-radius: calc(var(--app-base-spacing-2) * 0.8); */
    transition: all 0.3s;
    position: absolute;
    top: 7px;
    left: 7px;
}

.app-button-container-2 span {
    width: 100%;
    height: 100%;
    background-color: var(--app-primary-light);
    border-radius: var(--app-base-spacing-1);
    /* border-radius: calc(var(--app-base-spacing-2) * 0.8); */
    transition: all 0.3s;
    position: absolute;
    top: 7px;
    left: 7px;
}

.app-button-container-1:hover span {
    /* top: -5px;
    left: -8px; */

    top: 0px;
    left: 0px;
}
@media screen and (max-width: 1200px) {
    .app-button-1 {
        padding: 10px 12px 2px;
    }
}
@media screen and (max-width: 360px) {
    .app-button-1 {
        padding: 8px 10px 2px;
    }
}
