.services-page {
    --shadow: var(--app-primary-main);
}

.services-page .page-header .details {
    width: 60%;
    margin-right: var(--app-base-spacing-3);
}
.services-page .page-header .featured-image {
    width: 40%;
}
.services-page .page-header .featured-image img {
    width: 100%;
    max-width: 292px;
}

.services-page .about-section {
    /* margin-top: var(--app-base-spacing-5); */
    position: relative;
    z-index: 1;
}
.services-page .about-section .bg-image {
    position: absolute;
    top: 100px;
    left: 0;
    z-index: -1;
}
.services-page .about-section .bg-image img {
    max-height: 393px;
    height: 100%;
}
.services-page .about-section .content {
    z-index: 1;
}
.services-page .about-section .content .image {
    width: 45%;
}
.services-page .about-section .content .image img {
    max-width: 400px;
    margin-top: calc(-1 * var(--app-base-spacing-3));
}
.services-page .about-section .content .details {
    width: 55%;
    max-width: 653px;
    padding: var(--app-base-spacing-3);
}

.services-page .industry-section .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.services-page .industry-section .card-container .card {
    max-width: 292px;
    min-width: 262px;
    width: 25%;
    height: 186px;
    background-color: var(--app-color-white);
    border-bottom: 6px solid transparent;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    margin-left: var(--app-base-spacing-3);
    margin-bottom: var(--app-base-spacing-5);
}

.services-page .industry-section .card-container .card:nth-of-type(1) {
    --shadow: var(--app-primary-main);
}
.services-page .industry-section .card-container .card:nth-of-type(2) {
    --shadow: #5d52fd;
}
.services-page .industry-section .card-container .card:nth-of-type(3) {
    --shadow: #6bbf44;
}
.services-page .industry-section .card-container .card:nth-of-type(4) {
    --shadow: #f760a3;
}
.services-page .industry-section .card-container .card:nth-of-type(5) {
    --shadow: #e13a51;
}
.services-page .industry-section .card-container .card:nth-of-type(6) {
    --shadow: #3db59f;
}
.services-page .industry-section .card-container .card:nth-of-type(7) {
    --shadow: #f0be1c;
}
.services-page .industry-section .card-container .card:nth-of-type(8) {
    --shadow: #823cf3;
}

.services-page .industry-section .card-container .card:hover {
    border-bottom: 6px solid var(--shadow);
}

.services-page .industry-section .card-container .card:hover .card-heading h4 {
    color: var(--shadow) !important;
}

.services-page .expertise-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.services-page .expertise-section::after {
    content: "";
    height: 358px;
    width: 560px;
    background-image: url(../img/bg-3.png);
    background-repeat: no-repeat;
    background-position: right;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}
.services-page .expertise-section::before {
    content: "";
    height: 358px;
    width: 560px;
    background-image: url(../img/bg-4.png);
    background-repeat: no-repeat;
    background-position: initial;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}
.services-page .expertise-section .card-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    gap: var(--app-base-spacing-3);
}
.services-page .expertise-section .card-container .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* width: 145px; */
}
.services-page .expertise-section .card-container .card .card-header {
    margin-bottom: var(--app-base-spacing-1);
}
.services-page .expertise-section .card-container .card .card-header .card-image {
    margin: 0 auto;
    border-radius: 100px;
}
.services-page .expertise-section .card-container .card .card-header .card-image img {
    width: 4rem;
    height: 4rem;
}
.services-page .expertise-section .card-container .card .card-body .card-heading,
.services-page .expertise-section .card-container .card .card-body .card-description {
    color: var(--app-color-white);
    /* margin-bottom: var(--app-base-spacing-1); */
    text-align: center;
}
.services-page .expertise-section .card-container .card .card-body .card-description {
    margin-bottom: 0;
}

.services-page .our-offering .content .details,
.services-page .our-offering .content .image {
    width: 50%;
}
.services-page .our-offering .content .image img {
    width: 100%;
    max-width: 450px;
}

.services-page .banner-section {
    position: relative;
    z-index: 1;
}
.services-page .banner-section::after {
    content: "";
    height: 100%;
    width: 560px;
    background-image: url(../img/bg-3.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    opacity: 0.4;
}
.services-page .banner-section::before {
    content: "";
    height: 100%;
    width: 560px;
    background-image: url(../img/bg-4.png);
    background-repeat: no-repeat;
    background-position: initial;
    background-size: contain;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0.4;
}

.services-page .whyus-section {
    /* margin-top: var(--app-base-spacing-5); */
    position: relative;
    z-index: 1;
}
.services-page .whyus-section .bg-image {
    position: absolute;
    top: 150px;
    right: 0;
    z-index: -1;
}
.services-page .whyus-section .bg-image img {
    max-height: 393px;
    height: 100%;
}
.services-page .whyus-section .content .image img {
    max-width: 350px;
    width: 100%;
}
.services-page .whyus-section .bg-blob {
    width: 900px;
    position: absolute;
    top: 60%;
    left: -100px;
    transform: translateY(-50%);
    opacity: 0.2;
}
.services-page .whyus-section {
    position: relative;
}
.services-page .whyus-section .two .leftImage {
    position: absolute;
    left: 0;
}
.services-page .whyus-section .two .rightImage {
    position: absolute;
    right: 0;
}

@media screen and (max-width: 768px) {
    .services-page .industry-section .card-container {
        justify-content: center;
    }
    .services-page .industry-section .card-container .card {
        margin-bottom: var(--app-base-spacing-3);
    }

    .services-page .page-header .content {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .services-page .page-header .details {
        margin-right: 0;
        margin-top: var(--app-base-spacing-5);
        width: 100%;
    }
    .services-page .page-header .featured-image {
        width: 100%;
    }

    .services-page .about-section .bg-image img {
        max-height: 393px;
        height: auto;
        width: 100%;
    }
    .services-page .about-section .content {
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .services-page .about-section .content .details {
        padding: 0;
        width: 100%;
    }
    .services-page .about-section .content .image,
    .services-page .about-section .content .image img {
        width: 100%;
        margin-top: 0;
    }
    .services-page .about-section .content .image {
        margin-bottom: var(--app-base-spacing-3);
    }

    .services-page .expertise-section::before,
    .services-page .expertise-section::after {
        background-size: cover;
        height: 280px;
        width: 320px;
    }

    .services-page .banner-section::after {
        width: 100%;
    }
    .services-page .banner-section::before {
        width: 100%;
    }

    .services-page .our-offering .content {
        flex-wrap: wrap;
    }
    .services-page .our-offering .content .details,
    .services-page .our-offering .content .image {
        width: 100%;
    }
    .services-page .our-offering .content .image {
        margin-top: var(--app-base-spacing-4);
    }

    .services-page .whyus-section .content {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .services-page .whyus-section .content .details,
    .services-page .whyus-section .content .image {
        width: 100%;
    }

    .services-page .whyus-section .two img {
        width: 100%;
    }
    .services-page .whyus-section .content .details {
        margin-top: var(--app-base-spacing-5);
    }
}

@media screen and (max-width: 480px) {
    .services-page .industry-section .card-container .card {
        margin-left: 0;
    }

    .services-page .expertise-section .card-container {
        flex-direction: column;
    }
    .services-page .expertise-section::before,
    .services-page .expertise-section::after {
        background-size: contain;
        height: 220px;
        width: 260px;
    }
}
