.contact-us-page .contact-us-section .content {
    display: flex;
    justify-content: space-around;
    gap: var(--app-base-spacing-5);
    margin-top: var(--app-base-spacing-1);
    align-items: center;
}
.contact-us-page .contact-us-section .content .left {
    max-width: 650px;
    width: 100%;
    padding: var(--app-base-spacing-3);
    /* box-shadow: 0px 6px 18px rgba(16, 24, 40, 0.08); */
    border-radius: 24px;
}
.contact-us-page .contact-us-section .content .left form > * {
    margin-bottom: var(--app-base-spacing-2);
}

.contact-us-page .contact-us-section .content .left .contact-details {
    display: none;
}

.contact-us-page .contact-us-section .content .right .image img {
    width: 450px;
    height: 500px;
    object-fit: cover;
}

.contact-us-page .contact-review {
    position: relative;
    background: url("/public/img/Group 3.png");
    background-position: center;
    /* margin-bottom: 150px; */
    margin-top: 50px;
    background-size: cover;
    object-fit: cover;
}

.contact-us-page .contact-review::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    filter: brightness(40%);

    /* z-index: 1; */
}

.contact-us-page .contact-review .page-section,
.contact-us-page .contact-review .page-section .review-section {
    position: relative;
    z-index: 2;
    filter: brightness(100%);
}

.contact-us-page .contact-review .review-box {
    /* max-width: 90%; */
    /* margin-bottom: -180px; */
    border-radius: 20px;
    padding: 20px;
    width: 100%;
}

.contact-places-section .card-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: var(--app-base-spacing-3);
}
.making-feature .card-container {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;
    gap: var(--app-base-spacing-3);
}

.making-feature .card-container .card {
    padding: var(--app-base-spacing-1) var(--app-base-spacing-1);
    max-width: 300px;
    /* height: 170px; */
    width: 25%;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    border-bottom: 6px solid transparent;
}

.contact-places-section .card-container .card {
    padding: var(--app-base-spacing-3) var(--app-base-spacing-3);
    max-width: 195px;
    height: 150px;
    width: 20%;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    border-bottom: 6px solid transparent;
}
.contact-places-section .card-container .card:hover {
    border-bottom: 6px solid var(--app-primary-main);
}

.contact-places-section .card-container .card .card-head .card-img {
    max-width: 55px;
    width: 100%;
    max-height: 40px;
    height: 100%;
    object-fit: contain;
}

.contact-places-section .card-container .card:hover .card-head .card-img {
    filter: brightness(1);
}
/* .contact-places-section .card-container .card:hover .card-body .title {
} */
.contact-places-section .card-container .card .card-body .card-content {
    font-size: smaller;
}
/* .contact-places-section .card-container .card:hover .card-body .card-content {
    filter: saturate(200);
} */

.contact-us-page .page-section .meet-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    background-color: var(--app-primary-exlight);
    border-radius: var(--app-base-spacing-2);
}
.contact-us-page .page-section .meet-section::after {
    content: "";
    height: 358px;
    width: 560px;
    background-image: url(../img/bg-3.png);
    background-repeat: no-repeat;
    background-position: right;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    opacity: 0.2;
}
.contact-us-page .page-section .meet-section::before {
    content: "";
    height: 358px;
    width: 560px;
    background-image: url(../img/bg-4.png);
    background-repeat: no-repeat;
    background-position: initial;
    position: absolute;
    bottom: 0;
    opacity: 0.2;
    left: 0;
    z-index: -1;
}

@media screen and (max-width: 768px) {
    .contact-us-page .contact-us-section .contect-bg {
        display: none;
    }
    .contact-us-page .contact-us-section .content {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        align-items: center;
    }
    .contact-us-page .contact-us-section .content .left .contact-details {
        display: block;
    }
    .contact-us-page .contact-us-section .content .right {
        margin-left: 0;
    }
    /* .contact-us-page .contact-us-section .content .right .image img {
        width: 397px;
        height: 395px;
    } */
    .contact-us-page .contact-us-section .content .right .contact-details {
        display: none;
    }

    .contact-places-section .card-container .card {
        width: 70%;
    }
}

@media screen and (max-width: 480px) {
    .contact-us-page .contact-us-section .content .right .image img {
        width: 290px;
        height: 290px;
    }

    .contact-places-section .card-container .card {
        width: 100%;
    }

    .contact-us-page .contact-us-section .content .right .image {
        height: 30vh;
    }
}
