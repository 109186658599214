.slide-left {
    -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation-delay: 0.1s;
}
@-webkit-keyframes slide-left {
    0% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
@keyframes slide-left {
    0% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

.slide-right {
    -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation-delay: 0.1s;
}
@-webkit-keyframes slide-right {
    0% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
@keyframes slide-right {
    0% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

.shake-horizontal {
    -webkit-animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    animation-delay: 1s;
}
@-webkit-keyframes shake-horizontal {
    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
    80% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
    }
    90% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
    }
}
@keyframes shake-horizontal {
    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
    80% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
    }
    90% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
    }
}

.tracking-in-expand {
    -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
    animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
@-webkit-keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}
@keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}
