.slider .card {
    display: flex;
    /* align-items: center; */
    padding-inline: var(--app-base-spacing-4);
    border-radius: var(--app-base-spacing-1);
}
.slider .card .card-header {
    width: max-content;
    border-radius: var(--app-base-spacing-1);
    position: relative;
}
.slider .card .card-header .card-image {
    max-width: 400px;
    height: 450px;
    width: 100%;
    object-fit: contain;
    transition: all 0.3s;
}
.slider .card .card-body {
    width: 100%;
    /* width: max-content; */
    padding-top: var(--app-base-spacing-5);
    margin-top: var(--app-base-spacing-5);
    margin-right: var(--app-base-spacing-5);
}

.slider .card:hover .card-header .card-image {
    transform: scale(1.2);
}

.swiper-wrapper {
    width: 100%;
}

.slider .card .card-body .card-heading {
    margin-bottom: var(--app-base-spacing-3);
    /* font-weight: 600; */
    /* font-size: 26px; */
    /* line-height: 33px; */
    opacity: 0.9;
}
.slider .card .card-body .card-description {
    margin-bottom: var(--app-base-spacing-5);
    font-weight: 500;
    /* font-size: 16px; */
    /* line-height: 28px; */
}
.slider .card .card-body .card-description p {
    -webkit-line-clamp: 4;
    opacity: 0.6;
}
.slider .card .card-body .card-btn {
    width: 250px;
    height: 48px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.slider .card .card-body .card-btn .btn-text {
    margin-right: var(--app-base-spacing-1);
    font-weight: 500;
    font-size: 20px;
    opacity: 0.9;
}
.slider .cards-pagination > span {
    margin-left: var(--app-base-spacing-1);
}

.line {
    border-bottom-width: 2px;
    border-color: red;
}

.transform {
    transition: transform 0.4s ease-in-out;
}

.service :hover .service {
    transform: scale(1.5);
}

.contact-review {
    position: relative;
    background: url("/public/img/image 122.png");
    background-position: center;
    margin-bottom: 150px;
    margin-top: 50px;
    background-size: cover;
    object-fit: cover;
}

.contact-review::before {
    content: "";
    position: absolute;
    top: 0;

    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    filter: brightness(40%);
}

.contact-review .page-section,
.contact-review .page-section .review-box {
    filter: brightness(100%);
}

.contact-review .review-box {
    border-radius: 20px;
    padding: 20px;
    width: 100%;
}
