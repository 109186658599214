.about-page {
    --card-color: #3776ff;
}

.about-page .page-header .details {
    width: 60%;
    margin-right: var(--app-base-spacing-3);
}

.about-page .page-header .featured-image {
    width: 40%;
}
.about-page .page-header .featured-image img {
    width: 100%;
    max-width: 400px;
}

.about-page .grow-section {
    margin-top: var(--app-base-spacing-5);
    position: relative;
    z-index: 1;
}
.about-page .grow-section .bg-image {
    position: absolute;
    top: 100px;
    left: 0;
    z-index: -1;
}
.about-page .grow-section .bg-image img {
    max-height: 400px;
    width: 100%;
}
.about-page .grow-section .content {
    z-index: 1;
    justify-content: space-between;
}
.about-page .grow-section .content .image {
    width: 40%;
}
.about-page .grow-section .content .image img {
    max-width: 450px;
    margin-top: calc(-1 * var(--app-base-spacing-5));
}
.about-page .grow-section .content .details {
    width: 55%;
    max-width: 653px;
    padding: var(--app-base-spacing-3);
}

.about-page .numbers-section .card-container {
    display: flex;
    justify-content: center;
    align-items: stretch;
}
.about-page .numbers-section .card-container .card {
    padding: var(--app-base-spacing-3);
    margin-left: var(--app-base-spacing-3);
    margin-bottom: var(--app-base-spacing-3);
    width: 100%;
    max-width: 200px;
    background-color: var(--app-primary-exlight);
    border-radius: var(--app-base-spacing-2);
}
.about-page .numbers-section .card-container .card:hover {
    box-shadow: 0px 4px 14px 0px #00000014;
}

.about-page .numbers-section .card-container .card .card-head {
    margin: 0 auto;
    height: 80px;
    width: 80px;
    box-shadow: 0px 4px 14px 0px #00000014;
    margin-bottom: var(--app-base-spacing-2);
    border-radius: 100px;
    background-color: white;
    border-radius: 100%;
}
.about-page .numbers-section .card-container .card .card-head img {
    margin-top: 12px;
    transform: translate(15px, 13px);
}
.about-page .numbers-section .card-container .card .card-body {
    width: 100%;
}
.about-page .numbers-section .card-container .card .card-body .card-heading {
    width: 100%;
    /* background-color: var(--app-primary-light); */
    margin-bottom: var(--app-base-spacing-2);
}
.about-page .numbers-section .card-container .card .card-body .card-description {
    text-align: center;
    color: var(--app-text-light);
}

.about-page .service-section .card-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.about-page .service-section .card-container .card {
    width: 327px;
    padding: var(--app-base-spacing-3) var(--app-base-spacing-2);
    margin-left: var(--app-base-spacing-5);
    margin-bottom: var(--app-base-spacing-5);
    background: var(--app-color-white);
    box-shadow: 0px 6px 18px rgba(16, 24, 40, 0.08);
    border-radius: 12px;
    transition: all 1s;
    overflow: hidden;
}
.about-page .service-section .card-container .card:hover {
    background: url(../img/CardHoverBg.png);
    background-size: cover;
    background-repeat: no-repeat;
    transform: scale(1.1);
}
.about-page .service-section .card-container .card .card-head {
    margin-bottom: var(--app-base-spacing-2);
}
.about-page .service-section .card-container .card .card-head .card-img {
    max-width: 120px;
    width: 100%;
    margin: auto;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.about-page .service-section .card-container .card .card-head .card-img img {
    filter: brightness(10);
    z-index: 1;
    position: absolute;
    left: 20%;
    top: 35%;
}
.about-page .service-section .card-container .card:hover .card-head .card-img img {
    filter: brightness(1);
    position: absolute;
    left: 20%;
    top: 35%;
}
.about-page .service-section .card-container .card .card-head .card-img .card-img-bg {
    z-index: -1;
}
.about-page .service-section .card-container .card .card-head .card-img .card-img-bg svg .logo-bg {
    fill: var(--app-primary-main);
}
.about-page .service-section .card-container .card:hover .card-head .card-img .card-img-bg svg .logo-bg {
    fill: white !important;
}
.about-page .service-section .card-container .card .card-body .card-heading {
    margin-bottom: var(--app-base-spacing-1);
}
.about-page .service-section .card-container .card:hover .card-body .card-heading {
    color: var(--app-color-white);
}
.about-page .service-section .card-container .card:hover .card-body .card-description {
    color: var(--app-color-white);
}

.about-page .mission-section .card-container {
    display: flex;
}
.about-page .mission-section .card-container .card {
    margin-bottom: var(--app-base-spacing-3);
    width: 100%;
}
.about-page .mission-section .card-container .card .card-head {
    margin: 0 auto;
    height: 110px;
    width: 110px;
    padding: 10px;
    margin-bottom: var(--app-base-spacing-2);
    border-radius: 100px;
    background-color: #f1f6ff;
}
.about-page .mission-section .card-container .card .card-body {
    width: 100%;
}
.about-page .mission-section .card-container .card .card-body .card-heading {
    width: 100%;
    padding: 10px 20px;
    background-color: #f1f6ff;
    margin-bottom: var(--app-base-spacing-2);
}
.about-page .mission-section .card-container .card .card-body .card-description {
    padding: 10px;
    text-align: center;
    color: var(--app-text-light);
}

.about-page .whyus-section .card-container .card:nth-of-type(1) {
    --card-color: #3776ff;
}
.about-page .whyus-section .card-container .card:nth-of-type(2) {
    --card-color: #f25d4d;
}
.about-page .whyus-section .card-container .card:nth-of-type(3) {
    --card-color: #9836e5;
}
.about-page .whyus-section .card-container .card:nth-of-type(4) {
    --card-color: #f69511;
}

.about-page .whyus-section .card-container .card {
    width: 272px;
    padding: var(--app-base-spacing-2);
    background-color: var(--app-color-white);
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-bottom: 6px solid transparent;
    border-radius: 8px;
}
.about-page .whyus-section .card-container .card:hover {
    border-bottom: 6px solid var(--card-color) !important;
}

.about-page .whyus-section .card-container .card .card-head {
    margin-bottom: var(--app-base-spacing-2);
}
.about-page .whyus-section .card-container .card .card-head .card-img {
    padding: 7px;
    width: 70px;
    height: 70px;
}
.about-page .whyus-section .card-container .card:hover .card-head .card-img {
    background: #edf3ff;
    width: 70px;
    height: 70px;
    border-radius: 100%;
}

.about-page .whyus-section .card-container .card .card-body .card-heading {
    margin-bottom: var(--app-base-spacing-1);
}

.about-page .whyus-section .card-container .card .card-description {
    color: var(--app-text-light);
}

.about-page .features-section .card-container .card {
    position: relative;
    width: 272px;
    background-color: var(--app-color-white);
    border-bottom: 6px solid;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    /* overflow: hidden; */
}

.about-page .features-section .card-container .card::before {
    content: "";
    width: 100%;
    height: 7px;
    position: absolute;
    bottom: -6px;
    left: 0;
    background-color: var(--app-color-white);
}
.about-page .features-section .card-container .card:hover::before {
    opacity: 0;
}
/* .about-page .features-section .card-container .card::after {
    content: attr(data-index);
    position: absolute;
    top: 5px;
    right: 10px;
    color: #f7f7f7;
    font-size: 100px;
    font-weight: 700;
    line-height: 1;
} */
.about-page .features-section .card-container .card .card-head {
    z-index: 1;
    position: relative;
}
.about-page .features-section .card-container .card .card-description {
    line-height: 23px;
}

/* @media screen and (max-width: 850px) {
    .about-page .page-header .content {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .about-page .page-header .details {
        margin-right: 0;
        margin-top: var(--app-base-spacing-5);
        width: 100%;
    }
    .about-page .page-header .featured-image {
        width: 100%;
    }
} */

@media screen and (max-width: 850px) {
    .about-page .page-header .details {
        margin-right: 0;
        margin-top: var(--app-base-spacing-5);
        width: 100%;
    }
    .about-page .page-header .featured-image {
        width: 100%;
    }

    .about-page .grow-section .bg-image img {
        max-height: 393px;
        height: auto;
        width: 100%;
    }
    .about-page .grow-section .content {
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .about-page .grow-section .content .details {
        padding: 0;
        width: 100%;
    }
    .about-page .grow-section .content .image,
    .about-page .grow-section .content .image img {
        width: 100%;
        margin-top: 0;
    }
    .about-page .grow-section .content .image {
        margin-bottom: var(--app-base-spacing-3);
    }
}

@media screen and (max-width: 480px) {
    .about-page .numbers-section .card-container .card {
        margin-left: 0;
    }
}
