.header-container {
    /* height: 112px; */
    z-index: 999;
    /* background-color: var(--app-primary-exlight); */
    background-color: var(--app-color-white);
    /* border-bottom: 1px solid #f3f3f3; */

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

.header-container.sticky-header {
    border-bottom: 1px solid #f3f3f3;
    background-color: var(--app-primary-exlight);
}

.header-container.sticky-header .app-header {
    padding: 18px 20px;
}

.header-container .app-header {
    margin: auto;
    padding: 26px 20px;
    transition: all 0.3s;
}
.header-container .app-header .left .logo img {
    max-width: 170px;
    width: 100%;
    min-width: 140px;
}

.menu-container .main {
    display: block;
}

.menu-container .offscreen {
    display: none;
}

.header-container .app-header .right .menu-btn {
    height: 20px;
    width: 30px;
    cursor: pointer;
    overflow: hidden;
    display: none;
}

.header-container .app-header .right .menu-btn span {
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 100px;
    background-color: var(--app-primary-main);
}

.header-container .app-header .right .menu-btn span:nth-child(1) {
    margin-bottom: 5px;
}
.header-container .app-header .right .menu-btn span:nth-child(2) {
    margin-bottom: 5px;
}

.HhJ8keLZ1SBupKzmsP0V {
    overflow: hidden !important;
}

@media screen and (max-width: 1050px) {
    .header-container .app-header .right .action-button {
        display: none !important;
    }
}

@media screen and (max-width: 920px) {
    .header-container .app-header .left .logo img {
        max-width: 155px;
    }
    /* .menu-container {
        display: none;
    } */

    .header-container .app-header .spacer {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .menu-container .main {
        display: none;
    }
    .menu-container .offscreen {
        display: block;
    }
    .header-container .app-header .left .logo img {
        width: 150px;
    }
    .header-container .app-header .right .menu-btn {
        display: block;
    }
    .header-container .app-header .right .action-button {
        display: flex !important;
        flex-direction: column;
    }
}

@media screen and (max-width: 480px) {
    .header-container .app-header .left .logo img {
        width: 120px;
    }

    /* .header-container .app-header .right .menu-btn {
        display: block;
    } */
}
