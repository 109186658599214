.heading-highlighted {
    position: relative;
    color: var(--app-primary-main);
}
/* .heading-highlighted::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 12px;
    background-image: url("../img/heading-bottom.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
} */
.heading-highlighted::before,
.heading-highlighted::after {
    content: "";
    width: 68px;
    height: 3px;
    background-color: var(--app-primary-main);
    transition: all 0.5s;
    border-radius: 100px;

    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
}
.heading-highlighted::before {
    width: 18px;
    left: 73px;
}
.transparent {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}
