.popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    width: 0;
    opacity: 0;
    padding: var(--app-base-spacing-2);
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.4);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}
.popup.show {
    height: 100%;
    width: 100%;
    opacity: 1;
    pointer-events: all;
}
.popup .container {
    position: relative;
    max-width: 1200px;
    max-height: 600px;
    min-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    border-radius: var(--app-base-spacing-1);
}

.popup .container .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.popup .container .contact-form {
    height: fit-content;
    padding: var(--app-base-spacing-3);
    background-color: var(--app-color-white);
    box-shadow: 0px 6px 18px rgba(16, 24, 40, 0.08);
    border-radius: 10px;
}
.popup .container .contact-form .min-w {
    min-width: 200px;
    flex-wrap: wrap;
}

.popup .details-banner .list p {
    line-height: 40px;
}

.popup .container .contact-form .details {
    margin-bottom: var(--app-base-spacing-2);
}
.popup .container .contact-form form {
    padding-bottom: var(--app-base-spacing-2);
}
.popup .container .contact-form form .field-group {
    margin-top: var(--app-base-spacing-2);
    width: 100%;
}

.contact-us-page .contact-us-section .content .left form > * {
    margin-bottom: var(--app-base-spacing-5);
}
.popup .container .contact-form form .field-group .input-field {
    max-width: 400px;
    width: 45%;
}
.popup .container .contact-form form select {
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
}
.popup .container .contact-form form textarea {
    resize: none;
    width: 100%;
    max-width: none !important;
}
.popup .container .contact-form form textarea,
.popup .container .contact-form form select,
.popup .container .contact-form form input {
    width: 100%;
    outline: 0;
    border: 1px solid;
    border-radius: calc(var(--app-base-spacing-1) * 0.8);
    padding: calc(var(--app-base-spacing-1) * 1.5) var(--app-base-spacing-2);
    font-size: 16px;
}
.popup .container .contact-form form input:focus {
}
.popup .container .contact-form form input::placeholder {
    color: #cccccc;
}
.popup .container .contact-form form .input-field label {
    font-size: 14px;
    display: block;
    margin-bottom: var(--app-base-spacing-1);
}

.popup .container .details-banner {
    width: 50%;
    height: 100%;
    padding: var(--app-base-spacing-3);
}

@media screen and (max-width: 920px) {
    .popup .container {
        flex-direction: column;
        max-height: calc(100vh - 5%);
    }

    .popup .container .contact-form,
    .popup .container .details-banner {
        width: auto;
    }
}
@media screen and (max-width: 768px) {
    .popup .container .top {
        flex-wrap: wrap-reverse;
    }
    .popup .container .form-container {
        width: 100% !important;
    }
    .popup .container .contact-form {
        margin-right: 1rem !important;
    }
    .popup .container .contact-details {
        flex-wrap: wrap;
    }
}
