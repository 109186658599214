.footer {
    margin-top: 30px;
    /* border-top: 1px solid #e3e3e3; */
    background-color: var(--app-color-white);
}

/* .footer .footer-bottom {
    z-index: 3;
    position: relative;
} */
.footer .menu-item {
    position: relative;
}

.custom-anchor:hover:before {
    width: 100%;
}
.custom-anchor:hover {
    background-position: 0;
}
.custom-anchor:before {
    content: "";
    background: var(--app-primary-main);
    display: block;
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 1px;
    transition: all 0.3s ease-in-out;
}
.map-section {
    background-color: var(--app-primary-exlight);
}
.map-section .page-section {
    z-index: 1;
    position: relative;
}

.map-section .footer-top {
    position: relative;
    margin-bottom: 1rem;
}
.map-section .footer-top .point-1 {
    position: absolute;
    right: 40.1%;
    top: 36%;
}
.map-section .footer-top .point-2 {
    position: absolute;
}
.map-section .footer-top .point-3 {
    position: absolute;
}
.map-section .footer-top .point-4 {
    position: absolute;
}
.map-section .footer-top .point-5 {
    position: absolute;
}
.map-section .footer-top .point-6 {
    position: absolute;
}
.map-section .card-container {
    display: flex;
    justify-content: space-between;
    gap: var(--app-base-spacing-2);

    padding-bottom: var(--app-base-spacing-3);
    border-bottom: 2px solid var(--app-primary-light);
    z-index: 2;
}
.map-section .card-container .card {
    max-width: 200px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}
.map-section .card-container .card .card-head .card-img {
    width: 70px;
    margin: 0 auto;
}
.map-section .card-container .card .card-head .card-img img {
    width: 100%;
}
.map-section .contact-details {
    /* display: flex;
    justify-content: space-between;
    gap: var(--app-base-spacing-1); */
    z-index: 2;
}

.map-section a span,
.map-section a p {
    color: #777 !important;
}
.map-section a:hover span,
.map-section a:hover p {
    color: white !important;
}

.map-section .contact-details .item {
    max-width: 400px;
    width: 33%;
}

.map-section .map-img img {
    max-width: 800px;
    width: 100%;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

@media screen and (max-width: 768px) {
    .map-section .contact-details {
        flex-wrap: wrap;
    }
    .map-section .contact-details .item {
        width: max-content;
    }

    .map-section .card-container {
        flex-wrap: wrap;
    }
}

.footer .footer-bottom {
    position: relative;
}
.footer .footer-bottom .pos-left {
    position: absolute;
    top: 0;
    left: 0;
}
.footer .footer-bottom .pos-bottom {
    position: absolute;
    bottom: 0;
    left: 45%;
}
.footer .footer-bottom .pos-right {
    position: absolute;
    bottom: 0;
    right: 0;
}

.social-links a {
    width: 80px;
    height: 80px;
    text-align: center;
    text-decoration: none;
    color: #000;
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.05);
    margin: 0 5px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    transition: transform 0.5s;
}

.social-links a .fab {
    font-size: 30px;
    line-height: 80px;
    position: relative;
    margin: auto;
    margin-top: 24px;
    z-index: 10;
    transition: color 0.5s;
}

.social-links a::after {
    content: "";
    width: 0;
    margin: auto;
    height: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 100%;
    background: #000;
    /* background: linear-gradient(-45deg, #ed1c94, #ffec17); */
    background: linear-gradient(-45deg, #ffffff, #6b91e2);
    /* background: radial-gradient(circle, #010a21 0%, #091970 50%); */
    /* background-image: radial-gradient(
        circle,
        #050c2e,
        #040e34,
        #041139,
        #04123f,
        #051345,
        #061346,
        #081448,
        #091449,
        #0a1446,
        #0b1342,
        #0b133f,
        #0c123c
    ); */
    position: absolute;
    transition: 0.5s;
}

.social-links a:hover::after {
    /* top: 0; */
    width: 100%;
    height: 100%;
}

.social-links a:hover .fab {
    color: #fff;
    fill: black;
}

.social-links a:hover {
    transform: translateY(-10px);
}

@media screen and (max-width: 768px) {
    .map-section .contact-details {
        flex-wrap: wrap;
    }
    .map-section .contact-details .item {
        width: max-content;
    }

    .map-section .card-container {
        flex-wrap: wrap;
    }
    .footer .footer-bottom .pos-bottom {
        left: 0;
    }
    .map-section .widget .texts {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 480px) {
    .map-section .card-container {
        justify-content: center;
    }
    .map-section .card-container .card {
        max-width: 100px;
    }
    .map-section .widget {
        flex-wrap: wrap;
    }
}
