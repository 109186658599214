.our-working-page .technology-section .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--app-base-spacing-2);
}

.our-working-page .technology-section .card-container .card {
    width: 550px;
    padding: var(--app-base-spacing-2);
}

.our-working-page .technology-section .card-container .card .card-head {
    position: relative;
}

.our-working-page .technology-section .card-container .card .card-head .card-img {
    max-width: 576px;
    width: 100%;
    height: 378px;
    object-fit: cover;
    border-radius: var(--app-base-spacing-1);
}
.our-working-page .technology-section .card-container .card .card-head .card-avatar-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

.our-working-page .technology-section .card-container .card .card-head .card-avatar-c .card-avatar {
    height: 140px;
    width: 140px;
    margin-top: -50px;
    border-radius: 100px;
    background-color: var(--app-color-white);
}

/* .our-working-page .technology-section .card-container .card .card-body .img-list {

} */

.our-working-page .technology-section .card-container .card .card-body .img-list img {
    width: 48px;
}
